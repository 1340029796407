import React from 'react';
import { Link } from 'gatsby';

const NotFound = () => {
  return (
    <div className="th-hero-page">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="th-hero-content">
              <h1 className="th-hero-content__header">Not Found</h1>
              <Link to="/" className="th-hero-content__anchor">
                Go Home
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
